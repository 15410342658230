// src/components/Home.js
import React from 'react';
import './Home.css'; // Import CSS file for styling

const Home = () => {
    return (
        <div className="home-container">
            <h1 className="home-title">Welcome to Creative Quotation</h1>
            <p className="home-description">"Creative Quotation" helps you managing your business by providing you the facility to create digital Quotations/Invoices for your clients just within 2 clicks.
                Make Easy, Quotations, Estimates and Receipts or invoices with simplest UI.
            </p>
        </div>
    );
};

export default Home;
