// src/components/ContactUs.js
import React, { useState } from 'react';
import './ContactUs.css'; // Import CSS file for styling

const ContactUs = () => {
    const [formData, setFormData] = useState({
        phoneNumber: '',
        email: '',
        title: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send contact details to server)
        console.log(formData);
    };

    return (
        <div className="contact-us-container">
            <h1 className="contact-us-title">Contact Us</h1>
            <form onSubmit={handleSubmit} className="contact-us-form">
                <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="contact-us-input"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="contact-us-input"
                />
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                    className="contact-us-input"
                />
                <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                    className="contact-us-textarea"
                ></textarea>
                <button type="submit" className="contact-us-button">Submit</button>
            </form>
        </div>
    );
};

export default ContactUs;
