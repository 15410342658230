import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import OtpInput from 'react18-input-otp';
import './DeleteAccount.css'; // Import your CSS file for styling

const DeleteAccount = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [showBlockA, setShowBlockA] = useState(true);
    const [showBlockB, setShowBlockB] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const [countdown, setCountdown] = useState(300); // 5 minutes countdown
    const [countdownIntervalId, setCountdownIntervalId] = useState(null); // Countdown interval id

    const validatePhoneNumber = () => {
        return phoneNumber.trim() !== '';
    };

    const validateOTP = () => {
        return otp.trim() !== '';
    };

    function getRandomMilliseconds() {
        // Generate a random number between 5000 and 15000
        const randomMilliseconds = Math.floor(Math.random() * (12000 - 3000 + 1)) + 3000;
        return randomMilliseconds;
    }

    const handlePhoneSubmit = () => {
        if (!validatePhoneNumber()) {
            setError('Please enter a valid phone number');
            return;
        }

        setShowProgress(true);
        setTimeout(() => {
            setShowProgress(false);
            setShowBlockA(false);
            setShowBlockB(true);
            startCountdown();
            setError('');
        }, getRandomMilliseconds());
    };

    const startCountdown = () => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setCountdownIntervalId(intervalId);
    };

    const handleResendOTP = () => {
        clearInterval(countdownIntervalId);
        setCountdown(300);
        startCountdown();
        setShowResendButton(false);
    };



    const handleOTPSubmit = () => {
        if (!validateOTP()) {
            setError('Please enter a valid OTP');
            return;
        }

        clearInterval(countdownIntervalId);

        setShowProgress(true);
        setShowResendButton(false);
        // setShowProgress(false);
        setCountdownIntervalId(null); // Clear the interval id
        setCountdown(null);


    };

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
        setError('');
    };

    useEffect(() => {
        if (countdown === 0) {
            clearInterval(countdownIntervalId);
            setShowResendButton(true);
        }
    }, [countdown, countdownIntervalId]);

    useEffect(() => {
        if (showBlockB && !showResendButton) {
            startCountdown();
        }
    }, [showBlockB, showResendButton]);

    return (
        <div>
            <div className="delete-account-warning">
                <p className="warning-header"><strong>Delete Account Warning</strong></p>
                <div className="warning-content">
                    <p>Are you sure you want to delete your account? This action is irreversible and will permanently remove all of your data associated with your account. Please consider the following points before proceeding:</p>
                    <ul>
                        <li><strong>Data Loss:</strong> Deleting your account will result in the loss of all your saved information, including profile details, settings, and any associated content.</li>
                        <li><strong>Loss of Access:</strong> Once your account is deleted, you will no longer be able to access any features or services provided by the platform.</li>
                        <li><strong>No Recovery:</strong> Once the account deletion process is complete, it cannot be undone. Ensure that you have backed up any essential data or information before proceeding with account deletion.</li>
                    </ul>
                    <p>To proceed with deleting your account, please confirm your decision by providing details below.</p>
                </div>
            </div>
            <div className="delete-account-container">

                {showBlockA && (
                    <div className="block">
                        <h2>Delete Account</h2>

                        <div className="phone-input-container">
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                maxLength={16}
                            />
                        </div>
                        {error && <p className="error">{error}</p>}
                        <button onClick={handlePhoneSubmit}>Submit</button>
                        {showProgress && <div className="progress-indicator">Sending OTP...</div>}
                    </div>
                )}
                {showBlockB && (
                    <div className="block">
                        <h2>Enter OTP</h2>
                        <p>Description goes here</p>
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            separator={<span>-</span>}
                        />
                        {countdown > 0 && (
                            <div className="countdown">Resend in: {Math.floor(countdown / 60)}:{countdown % 60}</div>
                        )}
                        {showResendButton && <button onClick={handleResendOTP}>Resend OTP</button>}
                        <button onClick={handleOTPSubmit}>Submit OTP</button>
                        {error && <p className="error">{error}</p>}
                        {showProgress && <div className="progress-indicator">Verifying OTP...</div>}
                    </div>
                )}
            </div>

        </div>
    );
};

export default DeleteAccount;
