// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import DeleteAccount from './components/DeleteAccount';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/delete-account" component={DeleteAccount} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
